body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.board {
    margin: auto;
    display: inline-block;
    cursor: pointer;
}

.board-row {
    margin: auto;
}

.board-row:after {
    clear: both;
    content: "";
    display: table;
}

.status {
    margin-bottom: 10px;
}

.square {
    background: #fff;
    border: 4px solid #999;
    float: left;
    font-size: 24px;
    font-size: calc(16px + 10vw);
    font-weight: bold;
    line-height: 34px;
    line-height: calc(11px + 10vw);
    height: 34px;
    height: calc(16px + 10vw);
    margin-right: -1px;
    margin-top: -1px;
    padding: 0;
    text-align: center;
    width: 34px;
    width: calc(16px + 10vw);
}

.square:focus {
    outline: none;
}

.square.square-open:hover {
    background-color: rgb(200,200,200);
    cursor: pointer;
}

.kbd-navigation .square:focus {
    background: #ddd;
}
body {
  font: 14px "Century Gothic", Futura, sans-serif;
  margin: 20px;
}

ol,
ul {
  padding-left: 30px;
}

.game-info {
  width: calc(48px + 30vw);
  margin: auto;
}

.buttons {
  width: 100%;
}

.game {
  width: calc(48px + 30vw);
  margin: auto;
}

.button {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 1em 1em;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  width: 47.5%;
}

.button:hover {
  background-color: rgb(16, 100, 18);
}
