.board {
    margin: auto;
    display: inline-block;
    cursor: pointer;
}

.board-row {
    margin: auto;
}

.board-row:after {
    clear: both;
    content: "";
    display: table;
}

.status {
    margin-bottom: 10px;
}

.square {
    background: #fff;
    border: 4px solid #999;
    float: left;
    font-size: 24px;
    font-size: calc(16px + 10vw);
    font-weight: bold;
    line-height: 34px;
    line-height: calc(11px + 10vw);
    height: 34px;
    height: calc(16px + 10vw);
    margin-right: -1px;
    margin-top: -1px;
    padding: 0;
    text-align: center;
    width: 34px;
    width: calc(16px + 10vw);
}

.square:focus {
    outline: none;
}

.square.square-open:hover {
    background-color: rgb(200,200,200);
    cursor: pointer;
}

.kbd-navigation .square:focus {
    background: #ddd;
}